.airiosoft_nip_popup_wrapper{
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.airiosoft_nip_popup_container{
    background-color: white;
    width: 30%;
    position: relative;
    padding: 40px;
}
.airiosoft_nip_popup_cross{
    position: absolute;
    top: -10px;
    right: -10px;
}
.airiosoft_nip_popup_cross button{
    background-color: white;
    border: none;
    outline: none;
    color: black;
    cursor: pointer;
    height: 30px;
    width: 30px;
    font-size: 14px;
    border: 1px solid black;
    border-radius: 50%;
}
.airiosoft_nip_popup_form{
    padding: 10px 0px;
}