
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1.5px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-decoration: none;
}

.admin_members_table{
    border: 2px solid black;
}
.admin_members_row{
    display: flex;
    align-items: stretch;
}
.admin_members_col{
    border-top: 2px solid black;
    border-right: 2px solid black;
}
.admin_members_row .admin_members_col:last-child{
    border-right: none;
    padding: 0 10px;
}

.admin_members_row .admin_members_col:nth-child(9) {
    padding: 0 10px;
}
.admin_members_th{
    background-color: black;
}
.admin_members_th p{
    font-size: 14px;
    color: white;
    padding: 10px;
}
.admin_members_td p{
    font-size: 12px;
    color: black;
    padding: 10px;
}
.admin_members_td p a{
    color: black;
    font-weight: 500;
    text-decoration: underline;
}