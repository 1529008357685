.airiosoft_input_container{
    padding: 10px 0px;
}
.airiosoft_input_container label{
    width: 100%;
    font-size: 12px;
    font-weight: 500;
}
.airiosoft_input_container label span{
    color: red;
}
.airiosoft_input_container input{
    font-size: 12px;
    padding: 10px 10px;
    width: 100%;
}