.airiosoft_button_alt{
    padding: 10px 0px;
}
.airiosoft_button_alt button{
    border: 1px solid black;
    background-color: white;
    color: black;
    font-size: 14px;
    text-align: center;
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 500ms;
}
.airiosoft_button_alt button:hover{
    background-color: gainsboro;
}