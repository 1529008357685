.airiosoft_layout_wrapper{
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;
    width: 100vw;
}
.airiosoft_layout_container{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow-y: auto;
}
.airiosoft_layout_logo{
    position: fixed;
    left: 20px;
    top: 20px;
}
.airiosoft_layout_logo img{
    width: 100%;
    height: 50px;
    width: auto;
}