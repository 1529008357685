@media print {
table { page-break-inside: auto }
tr    { page-break-inside: avoid; page-break-after: auto; }
thead { display: table-header-group; }
tfoot { display: table-footer-group; }
@page { 
    size: auto;
    margin: 20mm 0 10mm 0;
}
body {
    margin: 0;
    padding: 0;
}
}
.admin_points_activity_card_display_wrapper_p{
    margin: 30px;
}
.admin_points_activity_card_display_container{
    width: 500px;
}
.points_card_backgound{
    border-radius: 20px;
    background-color: aliceblue;
    padding: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid black;
    -webkit-print-color-adjust: exact;
}
.points_card_row{
    display: flex;
    justify-content: space-between;
}
.points_card_spacer{
    height: 100px;
}
.points_card_logo{
    width: 150px;
    height: 50px;
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-print-color-adjust: exact;
}
.points_card_text h6{
    font-size: 25px;
    font-weight: 700;
}
.points_card_details p{
    text-align: right;
    padding: 5px 0px;
    font-size: 20px;
    font-weight: 300;
}
.points_card_details h5{
    text-align: right;
    font-size: 25px;
}
.points_card_qrcode_container{
    font-size: 40px;
}