.airiosoft_button{
    padding: 10px 0px;
}

.airiosoft_button.loading {
    opacity: 0.5;
    pointer-events: none;
}

.airiosoft_button button,
.airiosoft_button a {
    border: 1px solid black;
    background-color: black;
    color: white;
    font-size: 14px;
    text-align: center;
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 500ms;
}
.airiosoft_button button:hover,
.airiosoft_button a:hover {
    background-color: white;
    color: black;
}

.airiosoft_button button.loading,
.airiosoft_button a.loading {
    background-color: #ccc;
    color: #333;
    cursor: not-allowed;
}