.admin_member_item_container{
    padding: 20px;
}
.admin_member_item_details{
    margin-bottom: 30px;
}
.admin_member_item_history h3{
    font-size: 24px;
    font-weight: 500;
    padding: 20px 0px;
}
.admin_members_table{
    border: 2px solid black;
}
.admin_members_row{
    display: flex;
    align-items: stretch;
}
.admin_members_col{
    border-top: 2px solid black;
    border-right: 2px solid black;
}
.admin_members_row .admin_members_col:last-child{
    border-right: none;
}
.admin_members_th{
    background-color: black;
}
.admin_members_th p{
    font-size: 14px;
    color: white;
    padding: 10px;
}
.admin_members_td p{
    font-size: 12px;
    color: black;
    padding: 10px;
}