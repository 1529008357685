.admin_layout_topbar{
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 20px;
}
.admin_layout_logo img{
    width: 100%;
    height: 50px;
    width: auto;
}
.admin_layout_body{
    margin-top: 100px;
}
.admin_layout_urls{
    display: flex;
    align-items: center;
}
.admin_layout_url{
    margin: 0px 10px;
    padding: 5px 0px;
    position: relative;
}
.admin_layout_url_bar_container{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.admin_layout_url_bar{
    max-width: 0%;
    height: 2px;
    background-color: black;
    transition: all 500ms;
}
.admin_layout_url:hover .admin_layout_url_bar{
    max-width: 50%;
}
.admin_layout_url a{
    font-size: 14px;
    color: black;
}
.admin_layout_url_active a{
    font-size: 16px;
    font-weight: 500;
}