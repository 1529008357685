.admin_points_activity_card_container{
    padding: 20px;
}
.admin_points_activity_card_container h1{
    font-size: 24px;
    text-align: center;
    font-weight: 500;
}
.admin_points_activity_card_container h3{
    font-size: 21px;
    text-align: center;
}
.admin_points_activity_card_edits{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
}
.admin_points_activity_card_edit_item{
    padding: 10px;
}
.admin_points_activity_card_edit_item p{
    font-size: 12px;
    cursor: pointer;
}
.admin_points_activity_card_display_wrapper{
    margin: 30px 0px;
    display: flex;
    justify-content: center;
}
.admin_points_activity_card_display_container{
    width: 500px;
}
.points_card_backgound{
    border-radius: 20px;
    background-color: aliceblue;
    padding: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid black;
}
.points_card_row{
    display: flex;
    justify-content: space-between;
}
.points_card_spacer{
    height: 100px;
}
.points_card_logo{
    width: 150px;
    height: 50px;
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
}
.points_card_text h6{
    font-size: 25px;
    font-weight: 700;
}
.points_card_details p{
    text-align: right;
    padding: 5px 0px;
    font-size: 20px;
    font-weight: 300;
}
.points_card_details h5{
    text-align: right;
    font-size: 25px;
}
.points_card_qrcode_container{
    font-size: 40px;
}
.admin_points_activity_card_counter_wrapper{
    display: flex;
    justify-content: center;
    padding: 10px;
}
.admin_points_activity_card_counter_container{
    border: 1px solid black;
}

.admin_points_activity_card_counter_container.loading {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}
.admin_points_activity_card_counter_row{
    display: flex;
}
.admin_points_activity_card_counter_row button{
    padding: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 18px;
}
.admin_points_activity_card_counter_row input{
    text-align: center;
    background-color: transparent;
    border: none;
    outline: none;
    width: 100px;
    font-size: 12px;
    padding: 13px 10px;
}
.admin_points_activity_card_counter_row input::-webkit-outer-spin-button,
.admin_points_activity_card_counter_row input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.admin_points_activity_card_counter_row input[type=number] {
    -moz-appearance: textfield;
}
.admin_points_activity_card_download_wrapper{
    display: flex;
    justify-content: center;
}