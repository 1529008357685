.airiosoft_select_container{
    padding: 10px 0px;
}
.airiosoft_select_container label{
    width: 100%;
    font-size: 12px;
    font-weight: 500;
}
.airiosoft_select_container select{
    font-size: 12px;
    padding: 10px;
    width: 100%;
}