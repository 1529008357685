.admin_members_container{
    padding: 20px;
}
.admin_members_searchbox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.admin_members_searchinput{
    display: flex;
    border: 2px solid black;
    align-items: center;
}
.admin_members_searchinput input{
    border: none;
    outline: none;
    width: 100%;
    padding: 10px;
}
.admin_members_searchinput button{
    padding: 10px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
}
.admin_members_table{
    border: 2px solid black;
}
.admin_members_row{
    display: flex;
    align-items: stretch;
}
.admin_members_col{
    border-top: 2px solid black;
    border-right: 2px solid black;
}
.admin_members_row .admin_members_col:last-child{
    border-right: none;
}
.admin_members_th{
    background-color: black;
}
.admin_members_th p{
    font-size: 14px;
    color: white;
    padding: 10px;
}
.admin_members_td p{
    font-size: 12px;
    color: black;
    padding: 10px;
}