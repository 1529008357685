.airiosoft_loginpage_wrapper{
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 20px;
}
.airiosoft_loginpage_container{
    width: 500px;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
}
.airiosoft_loginpage_container h1{
    font-size: 24px;
    padding: 10px;
    margin: 0;
    text-align: center;
    font-weight: 700;
}
.airiosoft_loginpage_form{
    padding: 20px 0px;
}
.v2_web_public_login_form_or{
    padding: 25px 0px;
}
.v2_web_public_login_form_or p{
    font-size: 12px;
    font-weight: 400;
    color: black;
    font-family: 'Manrope', sans-serif;
    position: relative;
    text-align: center;
}
.v2_web_public_login_form_or p::before{
    content: "";
    height: 1px;
    background-color: black;
    width: calc(50% - 30px);
    position: absolute;
    display: block;
    left: 0px;
    top: 60%;
}
.v2_web_public_login_form_or p::after{
    content: "";
    height: 1px;
    background-color: black;
    width: calc(50% - 30px);
    position: absolute;
    display: block;
    right: 0px;
    top: 60%;
}