.airiosoft_signuppage_wrapper{
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 20px;
}
.airiosoft_signuppage_container{
    width: 500px;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
}
.airiosoft_signuppage_container h1{
    font-size: 24px;
    padding: 10px;
    margin: 0;
    text-align: center;
    font-weight: 700;
}
.airiosoft_signuppage_form{
    padding: 20px 0px;
}
.airiosoft_signuppage_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.airiosoft_signuppage_col33{
    width: 33%;
}
.airiosoft_signuppage_col50{
    width: 48%;
}