.airiosoft_admin_dashboard{
    padding: 20px;
}
.airiosoft_admin_boxes_row{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
.airiosoft_admin_boxes_col{
    width: 32%;
    background-color: black;
    border-radius: 5px;
}
.airiosoft_admin_boxes_container{
    padding: 20px;
}
.airiosoft_admin_boxes_container h3{
    font-size: 18px;
    color: white;
}
.airiosoft_admin_boxes_container p{
    font-size: 52px;
    color: white;
    padding: 10px 0px;
}
.airiosoft_admin_tables_row{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.airiosoft_admin_tables_col60{
    width: 58%;
}
.airiosoft_admin_tables_col40{
    width: 38%;
}
.airiosoft_admin_tables_row h3{
    font-size: 24px;
    font-weight: 500;
    padding: 10px 0px;
    color: black;
    margin: 0;
}