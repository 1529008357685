.admin_pointsettings_layout_row{
    display: flex;
}
.admin_pointsettings_layout_col250{
    width: 250px;
}
.admin_pointsettings_layout_nav_container{
    padding: 20px;
}
.admin_pointsettings_layout_col100{
    width: 100%;
}
.admin_pointsettings_layout_body{
    padding: 20px;
}
.admin_pointsettings_layout_nav_item{
    padding: 5px 0px;
    margin-bottom: 10px;
    position: relative;
}
.admin_pointsettings_layout_nav_item_bar_container{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.admin_pointsettings_layout_nav_item_bar{
    max-width: 0px;
    height: 2px;
    background-color: black;
    transition: all 500ms;
}
.admin_pointsettings_layout_nav_item:hover .admin_pointsettings_layout_nav_item_bar{
    max-width: 50px;
}
.admin_pointsettings_layout_nav_item a{
    font-size: 14px;
    color: black;
}
.admin_pointsettings_layout_nav_item_active a{
    font-size: 16px;
    font-weight: 500;
}